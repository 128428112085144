'use client'

import { useEffect } from 'react'
import { usePathname } from 'next/navigation'

export function SmoothScroll() {
  const pathname = usePathname()

  useEffect(() => {
    if (pathname === '/') {
      const handleClick = (e: MouseEvent) => {
        const target = e.target as HTMLAnchorElement
        if (target.hash && target.origin === window.location.origin && target.pathname === window.location.pathname) {
          e.preventDefault()
          const element = document.querySelector(target.hash)
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' })
          }
        }
      }

      document.addEventListener('click', handleClick)
      return () => document.removeEventListener('click', handleClick)
    }
  }, [pathname])

  return null
}